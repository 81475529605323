import React from "react";
import { useNavigate } from "react-router-dom";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import TextField from "../components/global/textfield";
import Button from "../components/global/button";
import ContactUsIcon from "../assets/SVGs/contact-us.svg";

const validationSchema = Yup.object({
  name: Yup.string()
    .max(50, "Name must be 50 characters or less")
    .required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  companyName: Yup.string()
    .max(50, "Company name should be less than 50 characters")
    .required("Company Name is required"),
  message: Yup.string()
    .max(255, "Message must be 255 characters or less")
    .required("Message is required"),
});

const ContactForm = ({ showImage, isPopup, onClose }) => {
  const [submissionStatus, setSubmissionStatus] = React.useState("");
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
      companyName: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        const response = await axios.post(
          "https://jc9ficjod2.execute-api.eu-central-1.amazonaws.com/prod/sendEmail",
          values
        );
        if (response.status === 200) {
          setSubmissionStatus("Your message has been sent successfully.");
          resetForm();
          navigate("/thank-you");
        } else {
          setSubmissionStatus(
            "Failed to send your message. Please try again later."
          );
        }
      } catch (error) {
        setSubmissionStatus(
          "Failed to send your message. Please try again later."
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <section
        className={`w-full h-full flex ${
          showImage ? "lg:flex-row" : "flex-col"
        } items-center ${showImage ? "lg:justify-start" : "justify-center"} ${
          showImage ? "flex-col-reverse" : "p-4"
        }`}
      >
        <form
          onSubmit={formik.handleSubmit}
          className={`flex flex-col gap-5 z-[2] w-[100%] ${
            showImage ? "lg:w-[50%] md:w-[70%]" : "lg:w-[90%]"
          } ${showImage ? "" : "normal"}`}
        >
          <h1
            className={`text-black font-bold ${
              showImage ? "md:text-5xl text-5xl" : "text-3xl"
            }`}
          >
            Contact Us
          </h1>
          <TextField
            type="text"
            onChange={formik.handleChange}
            name="name"
            value={formik.values.name}
            placeholder="Your Name"
            onBlur={formik.handleBlur}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="error">{formik.errors.name}</div>
          ) : null}

          <TextField
            type="email"
            onChange={formik.handleChange}
            name="email"
            value={formik.values.email}
            placeholder="Your Email"
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="error">{formik.errors.email}</div>
          ) : null}

          <TextField
            type="text"
            onChange={formik.handleChange}
            name="companyName"
            value={formik.values.companyName}
            placeholder="Company Name"
            onBlur={formik.handleBlur}
          />
          {formik.touched.companyName && formik.errors.companyName ? (
            <div className="error">{formik.errors.companyName}</div>
          ) : null}

          <TextField
            type="text"
            onChange={formik.handleChange}
            name="message"
            value={formik.values.message}
            placeholder="Your Message"
            multiline={true}
            onBlur={formik.handleBlur}
          />
          {formik.touched.message && formik.errors.message ? (
            <div className="error">{formik.errors.message}</div>
          ) : null}

          <Button
            type="submit"
            className={`w-[200px] shadow-primary text-white shadow-md ${
              showImage ? "lg:self-start self-center" : "self-center"
            } ${
              formik.isSubmitting ? "bg-gray-400" : "blue-gradient bg-primary"
            }`}
            label="Submit Details"
            disabled={formik.isSubmitting}
          />
          {submissionStatus && <p>{submissionStatus}</p>}
        </form>
        {showImage && (
          <img
            src={ContactUsIcon}
            alt="contact-us"
            className="grow p-15 lg:w-[60%] w-[90%]"
          />
        )}
      </section>
    </FormikProvider>
  );
};

export default ContactForm;
