import PrimaryButton from "../components/global/button";

import MainImage from "../assets/SVGs/mainimg.svg";
import Bug from "../assets/SVGs/bug.svg";
import SecondImage from "../assets/SVGs/img2.svg";
import ChartImage from "../assets/SVGs/chart.gif";
import InfrastructureIcon from "../assets/SVGs/infrastructure.svg";
import GoodbyeIcon from "../assets/SVGs/goodbye.svg";
import StressTestIcon from "../assets/SVGs/stress-test.svg";
import MedicalAppIcon from "../assets/SVGs/medical-app.svg";
import ReviewIcon from "../assets/SVGs/review.svg";
import OldWayIcon from "../assets/SVGs/old-way.svg";
import NewWayIcon from "../assets/SVGs/new-way.svg";
import PersonCodingIcon from "../assets/SVGs/person-coding.svg";
import UnderlineIcon from "../assets/SVGs/underline.svg";
import { Helmet } from "react-helmet";
import encantologo from "../assets/SVGs/encanto.png";
import gamesgift from "../assets/SVGs/gamersgift.png";
import omr from "../assets/SVGs/omr.png";

export default function Homescreen() {
  const items = [
    {
      label: "Run thousands of tests simultaneously",
      icon: InfrastructureIcon,
    },
    {
      label: "Say Hi to getting results in minutes",
      icon: GoodbyeIcon,
    },
    {
      label: "Trigger tests seamlessly from any Cl platform",
      icon: StressTestIcon,
    },
    {
      label: "Run directly from Slack for on-demand app health checkups",
      icon: MedicalAppIcon,
    },
    {
      label: "Critical tests are run first delivering even faster feedback",
      icon: ReviewIcon,
    },
  ];
  const testimonials = [
    {
      name: "Deepesh Sonaniya",
      position: "CEO, Encanto technologies",
      testimony:
        "QAbolts has transformed our testing process. The speed and accuracy are unmatched. We’re able to release features with confidence knowing that QAbolts has our back.",
      icon: encantologo,
    },
    {
      name: "Alex Johnson",
      position: "Lead Developer, Gamersgift",
      testimony:
        "The team at QAbolts is phenomenal. They quickly integrated into our workflow and provided valuable insights that improved our product quality significantly.",
      icon: gamesgift,
    },
    {
      name: "Anurag Tiwari",
      position: "Director, OMR Global",
      testimony:
        "QAbolts' automation framework is robust and easy to use. We’ve reduced our testing time by half, and our defect rate has dropped significantly.",
      icon: omr,
    },
  ];

  return (
    <>
      <Helmet>
        <title>QAbolts - Pay Only for Results, Not for the Process – Scalable end to end QA Automation on Demand.</title>
        <meta
          name="description"
          content="Pay only for what you use. QA Bolts specializes in QA automation services, providing startups with comprehensive solutions to ensure top-notch software quality. Explore our services, discover our expertise, and see how we can help elevate your product’s reliability and performance."
        />
      </Helmet>
      <section className="flex md:flex-row flex-col-reverse md:gap-0 gap-10 md:justify-start justify-end w-full items-center relative md:mb-[150px] mb-0 mt-[40px] md:mt-[200px]">
      <div className="flex flex-col w-[100%] lg:w-[60%] md:w-[80%] gap-5 z-[2] md:items-start items-center">
                <h1 className="text-black font-bold text-5xl flex flex-col gap-3 md:items-start items-center">
            <span className="text-primary flex items-center text-nowrap">
              Zap B
              <img
                src={Bug}
                alt="u"
                className="h-[55px] w-[55px] align-baseline object-contain"
              />
              gs Before{" "}
            </span>
            <span>Users Spot Them!</span>
          </h1>
          <h1 className="text-[#37474F] text-2xl font-semibold flex flex-wrap md:justify-start justify-center">
            <span>Your Shield Against</span>
            <span className=" text-black relative">
              &nbsp;Software Slip-Ups!
              <img
                src={UnderlineIcon}
                alt="underline-icon"
                className="w-[100%] h-[30px] absolute bottom-[-15px] right-0"
              />
            </span>
          </h1>
         
          <h1 className="text-primary-grey text-xl md:text-start text-center w-full md:w-[80%]">
          Automate your mobile and web apps: Pay only for test execution while we handle the rest.
              {/* <span className=" text-black relative">
              &nbsp;just 3 months &nbsp;
              <img
                src={UnderlineIcon}
                alt="underline-icon"
                className="w-[100%] h-[30px] absolute bottom-[-15px] right-0"
              />
            </span> */}
            <br></br>
            <br></br>
            Cut your QA automation costs
            <span className=" text-black relative">
              &nbsp;by 90%! &nbsp;
              <img
                src={UnderlineIcon}
                alt="underline-icon"
                className="w-[100%] h-[30px] absolute bottom-[-15px] right-0"
              />
            </span>
            
          </h1>
          
          <br></br>
          <PrimaryButton
            onClick={() =>
              window.open("https://calendly.com/talib-qabolts/30min", "_blank")
            }
            className="w-[300px] blue-gradient shadow-primary bg-primary text-white font-bold shadow-md"
            label="Book a free consultation"
          />
        </div>
        <img
          src={MainImage}
          alt="main_image"
          className="md:absolute static h-max md:w-[50%] w-[100%] right-0 z-[1]"
        />
      </section>

      <section className="w-full mt-10">
        <div className="w-full flex flex-col items-center text-center bg-white shadow-md rounded-[1.5rem] p-10">
          <h1 className="text-black font-bold md:text-4xl text-3xl mb-10">
            What Our Clients Say
          </h1>
          <div className="flex flex-col sm:flex-row flex-wrap gap-10 justify-items-around">
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className="flex flex-col items-center text-center basis-[30%]"
              >
                <div className=" h-[25px] w-[300px] grid place-content-center mb-4">
                  <img
                    src={testimonial.icon}
                    alt={testimonial.icon}
                    // className="h-[100%]"
                  />
                </div>
                <p className="text-xl font-semibold">{testimonial.name}</p>
                <p className="text-md text-primary-grey mb-4">
                  {testimonial.position}
                </p>
                <p className="text-md font-medium text-[#455A64]">
                  {testimonial.testimony}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="w-full">
        <div className="w-full rounded-[1.5rem] bg-white shadow-md flex md:flex-row flex-col items-center p-5 gap-5">
          <div className="p-5 w-[100%] xl:w-[100%] lg:w-[100%]">
            <img src={SecondImage} alt="second-img" className="h-auto" />
          </div>
          <div className="flex flex-col gap-5 md:p-5 p-2">
            <h1 className="text-black font-bold md:text-4xl text-3xl md:text-start text-center">
              Your true
              <span className="text-primary"> Plug and Play </span>
              QA team
            </h1>

            <p
              style={{ lineHeight: "30px" }}
              className="font-medium text-[#455A64] md:text-start text-center"
            >
              End-to-end (E2E) tests are one of the most important parts of a
              comprehensive testing strategy, but setting up E2E testing
              automation is complex, requiring detailed test cases and handling
              third-party APIs.
              <br />
              <br />
              <span className="flex items-start gap-2">
                <span className="text-green-500">✔</span>
                <span>
                  <span className="text-primary">QAbolts</span> manages all your
                  testing needs, from setup to execution to maintenance.
                </span>
              </span>
              <span className="flex items-start gap-2">
                <span className="text-green-500">✔</span>
                <span>
                  Your app is thoroughly tested for{" "}
                  <b className="underline">
                    functionality, performance, and accessibility
                  </b>
                  , providing comprehensive results.
                </span>
              </span>
              <span className="flex items-start gap-2">
                <span className="text-green-500">✔</span>
                <span>
                  Meet high-quality standards through our expert testing
                  solutions.
                </span>
              </span>
            </p>
          </div>
        </div>
      </section>

      <section className="w-full">
        <div className="w-full rounded-[1.5rem] bg-white shadow-md flex md:flex-row flex-col items-center p-5 gap-5">
          <div className="flex flex-col gap-5 md:p-5 p-2 md:w-3/5 w-full">
            <h1 className="text-black font-bold md:text-4xl text-3xl md:text-start text-center">
              Rapid Result,
              <span className="text-primary"> Lasting Impact </span>
              {/* <span className="text-primary"> QAbolts</span> */}
            </h1>
            <p
              style={{ lineHeight: "30px" }}
              className="font-medium text-[#455A64] md:text-start text-center"
            >
              <span className="flex items-start gap-2">
                <span className="text-green-500">✔</span>
                <span>
                Straightforward approach to QA automation, without charging per test or by the hour.
                </span>
              </span>
              <span className="flex items-start gap-2">
                <span className="text-green-500">✔</span>
                <span>
                Focus on covering every edge and corner case to ensure your users get the best experience.
                </span>
              </span>
              <span className="flex items-start gap-2">
                <span className="text-green-500">✔</span>
                <span>
                  <b className="underline">
                  Guaranteed automation of 50% of critical flows in the first month and 80% within three months.
                  </b>
                </span>
              </span>
              <span className="flex items-start gap-2">
                <span className="text-green-500">✔</span>
                <span>
                Costs less than hiring a single QA resource, providing full coverage at the lowest price.
                </span>
              </span>
            </p>
          </div>
          <div className="p-5 md:w-2/5 w-full">
            <img
              src={ChartImage}
              alt="chart"
              className="max-w-[450px] w-[100%] mt-5"
            />
          </div>
        </div>
      </section>

      <section className="w-full">
        <div className="w-full rounded-[1.5rem] bg-white shadow-md flex flex-col p-5 text-center">
          <h1 className="text-black font-bold md:text-4xl text-3xl my-5 mb-10">
            Execute Tests at{" "}
            <span className="text-primary">Lightning Pace</span>
          </h1>
          <div className="flex flex-col sm:flex-row flex-wrap gap-9 justify-items-around">
            {items.map((item, index) => (
              <div
                key={index}
                className="flex flex-col basis-[30%] gap-5 items-center grow"
              >
                <div className="blue-gradient rounded-md h-[90px] w-[90px] grid place-content-center">
                  <img src={item.icon} alt={index} className="h-[100%]" />
                </div>
                <p className="text-md font-medium text-[#455A64]">
                  <b>{item.label}</b>
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="w-full mt-10">
        <div className="w-full flex flex-col lg:flex-row gap-16 lg:gap-8">
          <div className="flex flex-col grow items-center relative shadow-md rounded-[40px] bg-white">
            <div className="bg-black text-white absolute top-[-25px] rounded-[40px] w-[80%] font-bold md:text-2xl text-xl h-14 px-4 flex items-center justify-center">
              The Old Way
            </div>
            <img
              src={OldWayIcon}
              alt="oldway"
              className="mt-[40px] w-[50%] lg:w-[80%]"
            />
            <div className="bg-[#EBEBEB] rounded-[40px] text-[#37474F] font-normal h-14 px-8 flex items-center justify-center">
              800 quasi-parallel 5 min tests :{" "}
              <span className="text-black font-medium">&nbsp;3 Hours</span>
            </div>
          </div>
          <div className="flex flex-col grow items-center relative shadow-md rounded-[40px] bg-on-primary">
            <div className="bg-black text-primary absolute top-[-25px] rounded-[40px] w-[80%] font-bold md:text-2xl text-xl h-14 px-4 flex items-center justify-center">
              The QAbolts Way
            </div>
            <img
              src={NewWayIcon}
              alt="newway"
              className="mt-[40px] w-[50%] lg:w-[80%]"
            />
            <div className="bg-primary-dark rounded-[40px] text-white font-normal h-14 px-8 flex items-center justify-center">
              800 fully-parallel 5 min Tests :{" "}
              <span className="text-white font-medium">&nbsp;5 Minutes</span>
            </div>
          </div>
        </div>
      </section>
      <section className="w-full mt-10">
        <div className="w-full flex flex-col lg:flex-row items-center lg:h-[500px] h-max">
          <div className="bg-white rounded-[50px] border border-primary shrink-0 h-full lg:w-[50%] w-[80%] z-[2] p-16 lg:p-0 rounded-b-none lg:rounded-b-[40px]">
            <img src={PersonCodingIcon} alt="person-coding" />
          </div>
          <div className="h-[80%] grow flex flex-col justify-between bg-primary rounded-[50px] lg:rounded-l-none rounded-br-[30px] ml-[-4px]">
            <div className="p-8 pl-10 gap-5">
              <h1 className="text-white font-bold md:text-4xl text-3xl my-3">
                Say goodbye to Flaky tests
              </h1>
              <p className="text-md text-white">
                End-to-end (E2E) tests often suffer from significant flakiness
                if they aren't designed with robustness and maintainability in
                mind. This frequently leads developers to spend hours each day
                troubleshooting false positives.
              </p>
              <br />
              <p className="text-md text-white">
                <b className="underline">
                  QAbolts guarantees zero flakes in your test runs. Our round
                  the clock QA team monitors failures in real-time, reproducing
                  issues before flagging them.
                </b>
              </p>
              <br />
              <p className="text-md text-white">
                Say goodbye to false alarms and hello to peace of mind with
                reliable, human-verified bug reports.
              </p>
            </div>
            <div
              className="bg-black rounded-[50px] lg:rounded-l-none 
                        w-[100%] text-white font-normal h-14 left-0 px-8 flex items-center justify-center shrink-0"
            >
              QAbolts: Flawless automation, seamless results.
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
