import LightningIcon from "../assets/SVGs/lightning.svg";
import CrossIcon from "../assets/SVGs/cross.svg";
import { useState } from "react";
import { Helmet } from "react-helmet";
import Minus from "../assets/SVGs/minus.svg";
import Plus from "../assets/SVGs/plus.svg";

export default function WhyUs() {
    const faqItems = [
        {
            question: "What is QAbolts?",
            answer: "QAbolts is a web and mobile end-to-end test automation service company. We specialize in creating, executing, and maintaining comprehensive automation frameworks tailored to your software’s needs. Our goal is to ensure your applications perform flawlessly by eliminating regression issues and enhancing overall quality through meticulous testing strategies and seamless CI/CD integration.",
        },
        {
            question: "How is QAbolts different from other end-to-end automation vendors?",
            answer: "We believe in adding value to your team, and our processes and pricing reflect that. Unlike most other vendors who use a subscription model with limits on the number of tests they can automate/maintain or charge per test automated, we offer a fixed-price model. Our pricing is highly competitive, typically costing less than the monthly salary of a single QA engineer. Additionally, you get unlimited free test runs with 100% parallelization.",
        },
        {
            question: "I can write automation with a no-code software tool. Why should I hire QAbolts?",
            answer: "No-code tools are perfect if you have a fairly simple workflow or a relatively small application. However, no-code tools typically follow a 20-80 ratio, where you spend 20% of your time creating automation and 80% maintaining it. If your application is constantly evolving, it can create a lot of flakiness, and you'll spend hours fixing relatively trivial issues. At QAbolts, our tests are built with robustness and maintainability in mind, so maintenance is lightning quick and issues are often resolved before you even notice.",
        },
        {
            question: "How does QAbolts handle the constant addition of features to our application?",
            answer: "QAbolts works closely with your team. Whenever you have new features, simply share the detailed documents or videos about the new features with us, and we will start writing test cases and automating them. We will integrate these into the regression suite, all within one week.",
        },
        {
            question: "Does QAbolts charge for new feature requests or major UI refactoring?",
            answer: "Short answer, no! We don't charge for automating new features or any required maintenance, even if you revamp your entire front end. You pay the same agreed monthly cost.",
        },
        {
            question: "I have hundreds of 3rd party integrations in my application. Is there any limit to what QAbolts can handle?",
            answer: "If a normal user can use a feature, we can automate it, no matter how complex. We have successfully automated features involving payment gateways, third-party databases, APIs, and even Java web applications. Whatever your needs are, we can automate them.",
        },
    ];

    const [expandedQuestion, setExpandedQuestion] = useState(null);
    const items = [
        {
            label: "Traceability matrix between features and tests",
            with: true,
            without: true,
        },
        {
            label: "Seamless CICD / Slack Integration",
            with: true,
            without: true,
        },
        {
            label: "50% e2e test coverage in 1 month",
            with: true,
            without: false,
        },
        {
            label: "Zero Flake Guarantee for Reliable Results",
            with: true,
            without: false,
        },
        {
            label: "Unlimited Test Runs with No Extra Cost",
            with: true,
            without: false,
        },
        {
            label: "Fixed monthly cost including test runs",
            with: true,
            without: false,
        },
        {
            label: "Pass/Fail Results in Under 15 Minutes",
            with: true,
            without: false,
        },
        {
            label: "Free automation for new features",
            with: true,
            without: false,
        },
        {
            label: "Unlimited automated tests",
            with: true,
            without: false,
        }
    ];

    return <div className="flex flex-col w-full items-center">
        <Helmet>
            <title>QAbolts : Why Us</title>
            <meta name="description" content="Find out why QA Bolts is the best choice for your QA automation needs. Learn about our expertise, proven track record, and commitment to delivering exceptional quality assurance solutions tailored to startups" />
        </Helmet>
        <h1 className="text-black font-bold text-5xl my-[100px] text-center text-nowrap items-center flex flex-col md:flex-row">
            <span>Why</span>
            <span className="text-primary bg-white rounded-2xl p-1  md:mt-0 mt-3">&nbsp;QAbolts</span>
        </h1>
        <div className="rounded-[30px] bg-white shadow-lg w-full md:p-8 p-4 flex flex-col gap-5">
            <div className="flex w-full">
                <div className="w-[70%] md:w-[75%] lg:w-[60%]">
                    <div className="bg-primary rounded-[40px] w-[40%] max-w-[250px] min-w-[150px] h-11 grid place-content-center">
                        <h1 className="text-white md:text-xl text-md">Features</h1>
                    </div>
                </div>
                <div className="grow flex gap-5">
                    <div className="md:bg-black rounded-[40px] h-11 grow grid place-content-center">
                        <h1 className="md:text-white md:text-xl text-md">Without</h1>
                    </div>
                    <div className="md:bg-green-500 rounded-[40px] h-11 grow grid place-content-center">
                        <h1 className="md:text-white md:text-xl text-md">With</h1>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-5">
                {items.map(item => (
                    <div key={item.label} className="flex w-full">
                        <div className="w-[70%] md:w-[75%] lg:w-[60%] ps-3">
                            <h1 className="text-[#455A64] md:text-lg text-md">{item.label}</h1>
                        </div>
                        <div className="grow flex gap-5">
                            <div className="grow grid place-content-center">
                                {item.without ?
                                    <img src={LightningIcon} alt="Lightning" className="md:h-[25px] md:w-[25px] w-[20px] h-[20px]" /> :
                                    <img src={CrossIcon} alt="Cross" className="md:h-[25px] md:w-[25px] w-[20px] h-[20px]" />}
                            </div>
                            <div className="grow grid place-content-center">
                                {item.with ?
                                    <img src={LightningIcon} alt="Lightning" className="md:h-[25px] md:w-[25px] w-[20px] h-[20px]" /> :
                                    <img src={CrossIcon} alt="Cross" className="md:h-[25px] md:w-[25px] w-[20px] h-[20px]" />}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        <section className="flex flex-row w-full items-center md:mt-[100px] mt-[50px]">
            <div className="w-full flex flex-col relative items-center">
                <div className="bg-black text-white absolute top-[-25px] rounded-[40px] w-[60%] font-bold md:text-2xl text-xl h-14 z-[2] px-4 flex items-center justify-center">
                    FAQ
                </div>
                <div className="bg-white rounded-[30px] shadow-lg w-full md:p-16 p-5">
                    <p style={{ lineHeight: "30px" }} className="text-md text-center font-medium text-[#455A64] my-10">
                        {/* Answers to Common Questions */}
                    </p>

                    <div className="flex flex-col gap-5">
                        {faqItems.map((item, index) => (
                            <div key={index} onClick={() => setExpandedQuestion(state => state === item.question ? null : item.question)}
                                className="flex gap-5 border border-border rounded-lg md:p-6 p-4 transition-all duration-200 hover:border-primary cursor-pointer">
                                <div className="flex flex-col gap-5 grow">
                                    <p style={{ lineHeight: "30px" }} className="text-md text-start font-semibold text-black">{item.question}</p>
                                    <p style={{ lineHeight: "30px" }}
                                        className={`text-md text-start font-medium text-[#455A64] 
                                            ${expandedQuestion === item.question ? "block" : "hidden"}`}>{item.answer}</p>
                                </div>
                                {expandedQuestion === item.question &&
                                    <img src={Minus} alt="minus" onClick={() => setExpandedQuestion(item.question)}
                                        className="h-[15px] shrink-0 w-[15px] cursor-pointer" />}
                                {expandedQuestion !== item.question &&
                                    <img src={Plus} alt="plus" onClick={() => setExpandedQuestion(null)}
                                        className="h-[15px] shrink-0 w-[15px] cursor-pointer" />}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    </div>;
}