import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/styles/index.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import HomePage from "./screens/homepage";
import Wrapper from "./screens/wrapper";
import HowItWorks from "./screens/howitworks";
import ContactUs from "./screens/contactus";
import WhyUs from "./screens/whyus";
import TermsAndConditions from "./screens/terms_conditions";
import PrivacyPolicy from "./screens/privacy_policy";
import CostCalculator from "./screens/cost_calculator";
import LandingPage from "./screens/landingpage";
import ThankYouPage from "./screens/ThankYouPage";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route element={<Wrapper />}>
        <Route path="/" element={<HomePage />}></Route>
        <Route path="/nuts-and-bolts" element={<HowItWorks />}></Route>
        <Route path="/contact-us" element={<ContactUs />}></Route>
        <Route path="/why-us" element={<WhyUs />}></Route>
        <Route
          path="/terms-and-conditions"
          element={<TermsAndConditions />}
        ></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route path="/cost-calculator" element={<CostCalculator />}></Route>
        <Route path="/lp" element={<LandingPage />}></Route>
      </Route>
      <Route path="/thank-you" element={<ThankYouPage />} />
    </Routes>
  </BrowserRouter>
);
