import React from "react";
import ContactForm from "./ContactForm";

const ContactUsPopup = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <ContactForm showImage={false} onClose={onClose} />
      </div>
    </div>
  );
};

export default ContactUsPopup;
