import MainSvgBackground from "../../assets/SVGs/background/main.svg";
import SecondarySvgbackground from "../../assets/SVGs/background/secondary.svg";

export default function HomescreenClipPaths() {
    return <div className="w-[100dvw] z-[0] absolute">
        <div className=" flex flex-col w-[100dvw]">
            <img src={MainSvgBackground} alt="main" className="max-w-[55dvw] min-w-[350px] h-max self-end object-cover" />
            {/* <img src={SecondarySvgbackground} alt="secondary" className="h-[40dvh] w-[50%] md:mt-0 mt-[250px] self-start object-cover ml-[-20%]" /> */}
            <div className="h-[50dvh]" />
            {/* <div src={SecondarySvgbackground} alt="secondary" className="h-[50dvh] w-[60%] rotate-180 self-end object-cover mr-[-20%]" /> */}
            <div className="h-[30dvh]" />
            {/* <div src={SecondarySvgbackground} alt="secondary" className="h-[40dvh] w-[50%] self-start object-cover ml-[-20%]" /> */}
        </div>
    </div>;
}