import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/global/header";
import HomescreenClipPaths from "../components/clippaths/HomescreenClipPaths";
import ContactUsClipPaths from "../components/clippaths/ContactUsClipPaths";
import { useEffect, useState } from "react";
import Footer from "../components/global/footer";
import WhyUsClipPaths from "../components/clippaths/WhyUsClipPaths";

export default function Wrapper() {
    const location = useLocation().pathname;
    const [clipPathComponent, setClipPathComponent] = useState(<HomescreenClipPaths />);

    useEffect(() => {
        if (location === "/contact-us") {
            setClipPathComponent(<ContactUsClipPaths />);
        }
        else if (location === "/why-us" || location === "/terms-and-conditions" || location === "/privacy-policy" || location === "/cost-calculator") {
            setClipPathComponent(<WhyUsClipPaths />);
        }
        else {
            setClipPathComponent(<HomescreenClipPaths />);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return <div className="w-[100dvw] h-[100dvh] bg-background-primary flex flex-col relative overflow-x-hidden">
        {clipPathComponent}
        <Header />
        <div className="flex flex-col h-full z-10">
            <div className="w-full flex h-full flex-col items-center">
                <div className={`${location === "/contact-us" ? "h-full" : ""} flex flex-col md:gap-24 gap-10 items-center w-[95%] lg:w-[70%] md:w-[80%] sm:w-[90%]`}>
                    <Outlet />
                </div>
                <Footer />
            </div>
        </div>
    </div>;
}