export default function Footer() {
    return (
        <footer className="w-full h-16 bg-black flex flex-col items-center justify-center mt-16 shrink-0">
            <div className="flex items-center justify-center space-x-4">
                <a href="/privacy-policy" className="text-white hover:underline">Privacy Policy</a>
                <a href="/terms-and-conditions" className="text-white hover:underline">Terms of Use</a>
                <a href="https://www.linkedin.com/company/qabolts" target="_blank" rel="noopener noreferrer" className="text-white hover:underline">LinkedIn</a>
                <a href="https://github.com/QABolts/QAbolts" target="_blank" rel="noopener noreferrer" className="text-white hover:underline">GitHub</a>
            </div>
            <h1 className="text-white mt-2">&copy; QAbolts 2024</h1>
        </footer>
    );
}
