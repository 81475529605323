import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "../../assets/SVGs/menu.svg";
import Logo from "../../assets/SVGs/logo.svg";
import CloseIcon from "../../assets/SVGs/close.svg";
import PrimaryButton from "./button";

export default function Header() {
    const [mobileMenuVisibility, setMobileMenuVisibility] = useState(false);
    const location = useLocation().pathname;
    const [activeItem, setActiveItem] = useState("Home");

    useEffect(() => {
        setMobileMenuVisibility(false);

        if (location === "/") {
            setActiveItem("Home");
        }
        else if (location === "/nuts-and-bolts") {
            setActiveItem("Nuts and Bolts");
        }
        else if (location === "/contact-us") {
            setActiveItem("Contact Us");
        }
        else if (location === "/why-us") {
            setActiveItem("Why Us");
        }
        else if (location === "/cost-calculator") {
            setActiveItem("Cost Calculator");
        }
        else {
            setActiveItem(null);
        }
    }, [location]);

    const items = [
        {
            name: "Home",
            path: "/",
        },
        {
            name: "Nuts and Bolts",
            path: "/nuts-and-bolts",
        },
        {
            name: "Why Us",
            path: "/why-us",
        },
        {
            name: "Contact Us",
            path: "/contact-us",
        },
        {
            name: "Cost Calculator",
            path: "/cost-calculator",
        },
    ];

    return (
        <header className="z-20 sticky top-0 bg-white shadow-md">
            <nav className="border-gray-200 px-4 lg:px-6 py-2.5">
                <div className="flex flex-wrap justify-between items-center w-full">
                    <a href="https://qabolts.com" className="flex items-center">
                        <img src={Logo} alt="logo" className="mr-6 h-9 sm:h-12" />
                    </a>
                    <div className="flex items-center lg:order-2">
                        <PrimaryButton onClick={() => window.open("https://calendly.com/talib-qabolts/30min", "_blank")} className={`bg-black blue-gradient text-white font-bold text-primary hidden lg:block`} shadow="shadow-white" label="Book a free consultation" />
                        <button onClick={() => setMobileMenuVisibility(state => !state)} type="button" className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 transition-all duration-200" aria-controls="mobile-menu-2" aria-expanded="false">
                            <span className="sr-only">Open main menu</span>
                            <img src={MenuIcon} alt="menu-icon" className={`${mobileMenuVisibility ? "hidden" : ""} w-6 h-6`} />
                            <img src={CloseIcon} alt="close-icon" className={`${!mobileMenuVisibility ? "hidden" : ""} w-6 h-6`} />
                        </button>
                    </div>
                    <div className={`${!mobileMenuVisibility ? "hidden" : ""} justify-between items-center w-full lg:flex lg:w-auto lg:order-1 relative`}>
                        <ul className="flex flex-col w-full z-10 lg:w-max mt-4 font-medium lg:flex-row absolute lg:static lg:space-x-8 lg:mt-0 bg-white lg:bg-transparent gap-2 py-3 rounded-[20px] shadow-lg lg:shadow-none">
                            {items.map(item => {
                                return (
                                    <li className="flex flex-col items-center" key={item.name}>
                                        <Link to={item.path}
                                            className={`block py-2 pr-4 pl-3 ${activeItem === item.name ? "text-primary" : "text-primary-grey"}
                                            hover:text-primary lg:p-0 transition-all duration-200 ease-in-out`}>
                                            {item.name}
                                        </Link>
                                        {activeItem === item.name &&
                                            <div className="h-1 w-1 rounded-[50%] bg-primary" />}
                                    </li>
                                );
                            })}
                            <PrimaryButton onClick={() => window.open("https://calendly.com/talib-qabolts/30min", "_blank")} className="transition-all duration-200 ease-in-out hover:text-primary block lg:hidden" shadow="shadow-white" label="Book a free consultation" />
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}
