import { useState } from "react";

import { ReactComponent as UnderlineIcon } from "../assets/SVGs/underline.svg";
import { ReactComponent as BotsMainImage } from "../assets/SVGs/botsmain.svg";
import { ReactComponent as Planbackground } from "../assets/SVGs/plan_background.svg";
import { ReactComponent as CodeBackground } from "../assets/SVGs/code_background.svg";
import { ReactComponent as RunInParallel } from "../assets/SVGs/run_parallel.svg";
import { ReactComponent as MaintainTest } from "../assets/SVGs/maintain_test.svg";
import MatrixImage from "../assets/PNGs/matrix.png";
import { Helmet } from "react-helmet";

export default function HowItWorks() {
    const tabItems = ["consult", "prepare", "implement", "integrate", "Support"];
    const [selectedTabItem, setSelectedTabItem] = useState(null);

    return <>
        <section className="flex md:flex-row flex-col-reverse md:gap-0 gap-10 md:justify-start justify-end w-full items-center relative md:mb-[150px] mb-0 mt-[40px] md:mt-[200px]">
            <Helmet>
                <title>QAbolts : Nuts & Bolts</title>
                <meta name="description" content="Discover how QA Bolts delivers seamless QA automation services. Learn about our process, from initial consultation to implementation, ensuring your software meets the highest quality standards efficiently." />
            </Helmet>
            <div className="flex flex-col w-[100%] lg:w-[60%] md:w-[80%] gap-5 z-[2] md:items-start items-center">
                <h1 className="text-black font-bold text-5xl flex flex-col gap-3 md:items-start items-center">
                    <span className="text-black flex items-center">Working With </span>
                    <span className="font-bold text-primary relative w-min">
                        QAbolts
                        <UnderlineIcon className="w-[100%] h-auto absolute bottom-[-8px] right-0" />
                    </span>
                </h1>
                <h1 className="text-primary-grey text-xl md:text-start text-center">
                    We specialize in creating, executing, maintaining, and enhancing
                    <br />
                     e2e automation for your software.
                    <br />
                    <br />
                    Our process ensures your application excels at every level and
                    <br />
                    eliminates regression issues through a customized strategy,
                    <br />
                    robust testing environments, detailed test cases, and
                    <br />
                     seamless CI/CD integration.
                    <br />
                    <br />
                    <br />
                    <br />
                    <span className="font-bold text-primary relative w-min">
                    Here’s a detailed look at our process:
                    </span>

                </h1>
            </div>
            <BotsMainImage className="h-max md:w-[50%] w-[70%] min-w-[300px] right-0 md:absolute static" />
        </section>
        <section className="flex flex-row w-full items-center">
            <div className="w-full flex flex-col items-center">
                <div className="bg-white rounded-2xl w-full h-14 flex items-center shadow-lg px-3 sticky top-[72px] z-10 gap-4">
                    {tabItems.map(item => (
                        <a onClick={() => setSelectedTabItem(item)} href={`#${item}-id`}
                            className={`uppercase font-medium transition-all duration-200 ${selectedTabItem === item ? "bg-primary text-white" : ""} hover:bg-primary hover:text-white grid place-content-center grow text-center h-10 rounded-lg md:p-3`}
                            key={item}>
                            {item}
                        </a>
                    ))}
                </div>

                <div className="flex flex-col md:gap-10 gap-5 md:mt-[100px] mt-[50px]">
                    <div id="consult-id" className="flex lg:flex-row flex-col-reverse items-center bg-white rounded-[50px] shadow-lg xl:px-20 xl:py-10 mt-10 px-14 py-8">
                        <div className="flex flex-col md:items-start md:w-[70%] w-[100%] gap-5">
                            <h1 className="text-black font-bold md:text-4xl text-3xl">
                                <span className="text-primary">Consult</span>
                            </h1>
                            <p style={{ lineHeight: "30px" }} className="text-md text-lg md:text-start font-medium text-[#455A64]">
                                Our process starts with a thorough consultation to understand your specific needs, objectives, and the scope of your software. We gather comprehensive requirements and identify key areas of concern, laying the groundwork for our testing strategy.
                                <br />

                            </p>
                        </div>
                        <Planbackground className="grow p-15 lg:w-max w-[100%]" />
                    </div>
                    <div id="prepare-id" className="flex lg:flex-row flex-col-reverse items-center bg-white rounded-[50px] shadow-lg xl:px-20 xl:py-10 mt-10 px-14 py-8">
                        <CodeBackground className="grow p-15 lg:w-max w-[100%]" />
                        <div className="flex flex-col md:w-[70%] w-[100%] gap-5">
                            <h1 className="text-black font-bold md:text-4xl text-3xl">
                                <span className="text-primary bg-white rounded-2xl p-1">Prepare</span>
                            </h1>
                            <p style={{ lineHeight: "30px" }} className="text-md text-lg font-medium text-[#455A64]">
                                We crafts a bespoke test strategy tailored to your application. which includes establishing a test environment that closely replicates your production setup such as configuring hardware, software, network settings, and any other dependencies to create a realistic testing scenario.
                                <br />
                                <br />
                                Our experienced testers create detailed test cases covering all critical functionalities and potential edge cases. Each test case is meticulously documented and reviewed to ensure it meets your requirements and testing objectives.
                            </p>
                        </div>
                    </div>
                    <div id="implement-id" className="flex lg:flex-row flex-col-reverse items-center bg-white rounded-[50px] shadow-lg xl:px-20 xl:py-10 mt-10 px-14 py-8">
                        <div className="flex flex-col lg:w-[70%] w-[100%] gap-5">
                            <h1 className="text-black font-bold md:text-4xl text-3xl">
                                <span className="text-primary">Implement</span>
                            </h1>
                            <p style={{ lineHeight: "30px" }} className="text-md text-lg  font-medium text-[#455A64]">
                                We implement automated test scripts using industry-leading tools to enhance efficiency and consistency. Our automation efforts cover functional, UI, and performance tests, enabling rapid and reliable execution of regression tests with special focus on running the entire suite of tests within 5 minutes.
                                <br />
                                <br />
                                We execute the test cases on the configured environment, thoroughly examining all specified areas to identify defects or regressions. Real-time monitoring ensures immediate detection and response to any issues.
                            </p>
                        </div>
                        <RunInParallel className="grow p-15 lg:w-max w-[100%]" />
                    </div>
                    <div id="integrate-id" className="flex items-center relative md:mb-0 sm:mb-[20%] mb-0">
                        <img src={MatrixImage} alt="matrix" className="md:w-[80%] w-[100%] mt-10 md:mt-0 hidden sm:block" />
                        <div className="lg:w-[60%] md:w-[80%] w-[100%] flex md:justify-end justify-center static right-0 md:mt-0 sm:mt-[30%] mt-0 sm:absolute">
                            <div className="bg-white rounded-[50px] shadow-lg border-primary flex flex-col gap-5 w-[100%] sm:w-[80%] md:w-[55%] min-w-[90%] p-10">
                                <h1 className="text-black font-bold md:text-4xl text-3xl">
                                    <span className="text-primary ">Integrate</span>
                                </h1>
                                <p style={{ lineHeight: "30px" }} className="text-md   text-lg font-medium text-[#455A64]">
                                    Our services integrate seamlessly with your CI/CD pipeline, automatically triggering regression tests with each code change. This continuous testing approach helps maintain high-quality standards and quick turnaround times.
                                    <br />
                                    <br />
                                    You can also trigger the tests through slack or teams for on demand app health checkups.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div id="Support-id" className="flex lg:flex-row flex-col-reverse items-center bg-white rounded-[50px] shadow-lg xl:px-20 xl:py-10 mt-10 px-14 py-8">
                        <MaintainTest className="grow p-15 lg:w-max w-[100%]" />
                        <div className="flex flex-col items-center sm:items-start md:w-[70%] w-[100%] gap-5">
                            <h1 className="text-primary font-bold md:text-4xl text-3xl">
                                Support
                            </h1>
                            <p style={{ lineHeight: "30px" }} className="text-md text-lg text-center sm:text-start font-medium text-[#455A64]">
                            After testing, we deliver detailed reports with insights into test results, identified issues, resolutions, and key metrics like coverage, pass/fail rates, and trends. Our commitment goes beyond reporting—we provide ongoing support for follow-up testing, retesting fixed issues, and enhancing your testing processes. 
                            <br>
                            </br>
                            <br></br>
                            We maintain transparent communication throughout the testing lifecycle, keeping you updated on progress, challenges, and outcomes. This collaborative approach ensures alignment and addresses any concerns promptly.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>;
}
