import TextField from "../components/global/textfield";
import Button from "../components/global/button";
import ContactUsIcon from "../assets/SVGs/contact-us.svg";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";

const validationSchema = Yup.object({
    name: Yup.string()
        .max(50, 'Name must be 50 characters or less')
        .required('Name is required'),
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    companyName: Yup.string()
        .max(50, "Company name should be less than 50 characters")
        .required("Company Name is required"),
    message: Yup.string()
        .max(255, 'Message must be 255 characters or less')
        .required('Message is required'),
});

export default function ContactUs() {
    const [submissionStatus, setSubmissionStatus] = useState("");
    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            message: "",
            companyName: ""
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            try {
                const response = await axios.post("https://jc9ficjod2.execute-api.eu-central-1.amazonaws.com/prod/sendEmail", values);
                if (response.status === 200) {
                    setSubmissionStatus("Your message has been sent successfully.");
                    resetForm();
                } else {
                    setSubmissionStatus("Failed to send your message. Please try again later.");
                }
            } catch (error) {
                setSubmissionStatus("Failed to send your message. Please try again later.");
            } finally {
                setSubmitting(false);
            }
        },
    });

    return (
        <FormikProvider value={formik}>
            <Helmet>
                <title>QAbolts : Contact Us</title>
                <meta name="description" content="Reach out to QA Bolts for expert QA automation services. Fill out our contact form for inquiries, support, or consultations. Our team is ready to assist you with all your quality assurance needs." />
            </Helmet>
            <section className="w-full h-full flex lg:flex-row items-center lg:justify-start justify-end flex-col-reverse">
                <form onSubmit={formik.handleSubmit} className="flex flex-col gap-5 z-[2] w-[100%] lg:w-[50%] md:w-[70%]">
                    <TextField
                        type="text"
                        onChange={formik.handleChange}
                        name="name"
                        value={formik.values.name}
                        placeholder="Your Name"
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.name && formik.errors.name ? (
                        <div className="error">{formik.errors.name}</div>
                    ) : null}
                    <TextField
                        type="email"
                        onChange={formik.handleChange}
                        name="email"
                        value={formik.values.email}
                        placeholder="Your Email"
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <div className="error">{formik.errors.email}</div>
                    ) : null}

                    <TextField
                        type="text"
                        onChange={formik.handleChange}
                        name="companyName"
                        value={formik.values.companyName}
                        placeholder="Company Name"
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.companyName && formik.errors.companyName ? (
                        <div className="error">{formik.errors.companyName}</div>
                    ) : null}

                    <TextField
                        type="text"
                        onChange={formik.handleChange}
                        name="message"
                        value={formik.values.message}
                        placeholder="Your Message"
                        multiline={true}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.message && formik.errors.message ? (
                        <div className="error">{formik.errors.message}</div>
                    ) : null}
                    <Button
                        type="submit"
                        className={`w-[200px] shadow-primary text-white shadow-md lg:self-start self-center ${formik.isSubmitting ? 'bg-gray-400' : 'blue-gradient bg-primary'}`}
                        label="Submit Details"
                        disabled={formik.isSubmitting}
                    />
                    {submissionStatus && <p>{submissionStatus}</p>}
                </form>
                <img src={ContactUsIcon} alt="contact-us" className="grow p-15 lg:w-[60%] w-[90%]" />
            </section>
        </FormikProvider>
    );
}
