import { FormikProvider, useFormik } from "formik";
import Button from "../components/global/button";
import TextField from "../components/global/textfield";
import { useState } from "react";
import { Helmet } from "react-helmet";

import Logo from "../assets/SVGs/logo.svg";
import SoftwareTester from "../assets/SVGs/software_tester.svg";

export default function CostCalculator() {
    const [haveManualTester, setManualTester] = useState(true);

    const formik = useFormik({
        initialValues: {
            "qa-numbers": 3,
            "average-salary": 4000,
            "execution-mins": 10,
            "execution-count-each-month": 2,
            "number-of-tests": 100,
            "testers-count": 1,
        }
    });

    function calculateTotalCost() {
        const result = validateTotal();
        if (!result) return "-";

        if (haveManualTester) {
            // (qa-numbers * average-salary) + ((testers-count * execution-mins * execution-count-month) / 60)
            return Math.floor(formik.values["qa-numbers"] * formik.values["average-salary"]) + ((formik.values["number-of-tests"] * formik.values["execution-mins"] * formik.values["execution-count-each-month"]) * 30 / 60);
        }
        else {
            // (qa-numbers * average-salary) + 2500;
            return Math.floor(formik.values["qa-numbers"] * formik.values["average-salary"]) + 1500 + ((500 * 10 * formik.values["execution-count-each-month"]) * 30 / 60);
        }
    }

    function validateTotal() {
        return !(formik.values["qa-numbers"] === "" || formik.values["average-salary"] === "" || formik.values["execution-mins"] === "" || formik.values["execution-count-each-month"] === "");
    }

    function calculateMonthlyCost() {
        const result = validateMonthly();
        if (!result) return "-";

        // max((testers-count * execution-mins * execution-count-each-month) / 60, 5000)
        return Math.min((formik.values["number-of-tests"] * formik.values["execution-mins"] * formik.values["execution-count-each-month"]) * 20 / 60, 5000);
    }

    function validateMonthly() {
        return !(formik.values["testers-count"] === "" || formik.values["execution-mins"] === "" || formik.values["execution-count-each-month"] * 30 === "");
    }

    return <div className="flex flex-col h-full z-10">
        <Helmet>
            <title>QAbolts : Cost Calculator</title>
            <meta name="description" content="Discover the cost savings of QA automation with QAbolts. Use our cost calculator to compare the expenses of in-house QA teams vs. outsourcing to QAbolts for efficient, scalable automation services." />
        </Helmet>
        <h1 className="text-black font-bold text-5xl my-[10px] text-center text-wrap flex flex-wrap justify-center w-full">
            <span className="text-primary bg-white rounded-2xl p-1">Cost&nbsp; Calculator</span>
            {/* <span className="text-primary bg-white rounded-2xl p-1">Calculator</span> */}
        </h1>
        <h2 className="text-black font-bold text-3xl my-[30px] text-center text-wrap flex flex-wrap justify-center w-full">
            <span >Inhouse vs QAbolts</span>
            {/* <span className="text-primary bg-white rounded-2xl p-1">Calculator</span> */}
        </h2>
        <div className="flex w-full gap-5 xl:flex-row flex-col">
            <FormikProvider value={formik}>
                
                <div className="flex flex-col items-center xl:w-1/3 w-full flex-shrink-0 min-w-[300px] gap-5 rounded-[1.5rem] bg-white border border-black shadow-md flex flex-col px-10 py-8 xl:w-1/3 w-full flex-shrink-0 min-w-[300px] items-center">
                
                    <div className="flex flex-col rounded-[1.5rem] bg-white shadow-md w-full px-5 py-8">
                        <h3 className="text-subtitle font-bold sm:text-lg text-base mb-2 text-wrap text-center">
                            Total number of QA you are planning to hire?
                        </h3>
                        <h4 className="text-subtitle sm:text-lg text-base mb-2 text-wrap text-center">Healthy Dev:QA ratio is 4:1</h4>
                        <br />
                        <input
                            type="range"
                            min="2"
                            max="10"
                            value={formik.values["qa-numbers"]}
                            name="qa-numbers"
                            onChange={formik.handleChange}
                            className="w-full"
                        />
                        <div className="text-center">{formik.values["qa-numbers"]}</div>
                    </div>

                    <div className="flex flex-col rounded-[1.5rem] bg-white shadow-md w-full px-5 py-8">
                        <h3 className="text-subtitle font-bold sm:text-lg text-base mb-2 text-wrap text-center">
                            Average salary per month( $ )
                        </h3>
                        <input
                            type="range"
                            min="3500"
                            max="15000"
                            value={formik.values["average-salary"]}
                            name="average-salary"
                            onChange={formik.handleChange}
                            className="w-full"
                        />
                        <div className="text-center">${formik.values["average-salary"]}</div>
                    </div>

                    {/* <div className="flex flex-col rounded-[1.5rem] bg-white shadow-md w-full px-5 py-8">
                            <h3 className="text-subtitle font-bold sm:text-lg text-base mb-2 text-wrap text-center">
                                Do you have a manual testcases?
                            </h3>
                            <div className="w-full flex gap-4 my-4 justify-center">
                                <Button
                                    type="button"
                                    className={`shadow-primary text-white bg-primary shadow-md max-w-[100px] w-full`}
                                    label="Yes"
                                    onClick={() => setManualTester(true)}
                                />
                                <Button
                                    type="button"
                                    className={`text-white bg-primary-grey shadow-md max-w-[100px] w-full`}
                                    label="No"
                                    onClick={() => setManualTester(false)}
                                />
                            </div>
                        </div> */}

                    {haveManualTester && (
                        <div className="flex flex-col rounded-[1.5rem] bg-white shadow-md w-full px-5 py-8">
                            <h3 className="text-subtitle font-bold sm:text-lg text-base mb-2 text-wrap text-center">
                                Current number of testcases?
                            </h3>
                            <input
                                type="range"
                                min="50"
                                max="2000"
                                value={formik.values["number-of-tests"]}
                                name="number-of-tests"
                                onChange={formik.handleChange}
                                className="w-full"
                            />
                            <div className="text-center">{formik.values["number-of-tests"]}</div>
                        </div>
                    )}
                    {haveManualTester && (
                        <div className="flex flex-col rounded-[1.5rem] bg-white shadow-md w-full px-5 py-8">
                            <h3 className="text-subtitle font-bold sm:text-lg text-base mb-2 text-wrap text-center">
                                Average manual execution time per test (mins)
                            </h3>
                            <input
                                type="range"
                                min="5"
                                max="30"
                                value={formik.values["execution-mins"]}
                                name="execution-mins"
                                onChange={formik.handleChange}
                                className="w-full"
                            />
                            <div className="text-center">{formik.values["execution-mins"]} min</div>
                        </div>
                    )}
                    <div className="flex flex-col rounded-[1.5rem] bg-white shadow-md w-full px-5 py-8">
                        <h3 className="text-subtitle font-bold sm:text-lg text-base mb-2 text-wrap text-center">
                            Number of deployments per day
                        </h3>
                        <input
                            type="range"
                            min="1"
                            max="15"
                            value={formik.values["execution-count-each-month"]}
                            name="execution-count-each-month"
                            onChange={formik.handleChange}
                            className="w-full"
                        />
                        <div className="text-center">{formik.values["execution-count-each-month"]}</div>
                    </div>

                </div>
            </FormikProvider>
            <div className="rounded-[1.5rem] bg-white shadow-md flex flex-col px-5 py-8 xl:w-1/3 border-black w-full flex-shrink-0 min-w-[300px]">
            
            <h1 className="text-black font-bold text-5xl my-[30px] text-center text-wrap flex flex-wrap justify-center w-full text-primary bg-white rounded-2xl p-1">In-house</h1>
                <div className="flex flex-col items-center gap-1 border-b border-b-border py-4 mx-16">
                    <h3 className="text-subtitle font-medium sm:text-lg text-base text-wrap text-center">
                        Total number of QA
                    </h3>
                    <h3 className="text-black font-bold sm:text-lg text-base text-wrap text-center">
                        {formik.values["qa-numbers"]}
                    </h3>
                </div>
                <div className="flex flex-col items-center gap-1 border-b border-b-border py-4 mx-16">
                    <h3 className="text-subtitle font-medium sm:text-lg text-base text-wrap text-center">
                        Average monthly salary
                    </h3>
                    <h3 className="text-black font-bold sm:text-lg text-base text-wrap text-center">
                        {formik.values["average-salary"]}$
                    </h3>
                </div>
                {/* <div className="flex flex-col items-center gap-1 border-b border-b-border py-4 mx-16">
                    <h3 className="text-subtitle font-medium sm:text-lg text-base text-wrap text-center">
                        Do you have Manual Testcases
                    </h3>
                    <h3 className="text-black font-bold sm:text-lg text-base text-wrap text-center">
                        {haveManualTester ? "Yes" : "No"}
                    </h3>
                </div> */}
                <div className="flex flex-col items-center gap-1 border-b border-b-border py-4 mx-16">
                    <h3 className="text-subtitle font-medium sm:text-lg text-base text-wrap text-center">
                        Number of testcases
                    </h3>
                    <h3 className="text-black font-bold sm:text-lg text-base text-wrap text-center">
                        {haveManualTester ? formik.values["number-of-tests"] : "-"}
                    </h3>
                </div>
                <div className="flex flex-col items-center gap-1 border-b border-b-border py-4 mx-16">
                    <h3 className="text-subtitle font-medium sm:text-lg text-base text-wrap text-center">
                        Test execution time
                    </h3>
                    <h3 className="text-black font-bold sm:text-lg text-base text-wrap text-center">
                        {haveManualTester ? formik.values["execution-mins"] + " mins" : "-"}
                    </h3>
                </div>
                {/* <div className="flex flex-col items-center gap-1 border-b border-b-border py-4 mx-16">
                    <h3 className="text-subtitle font-medium sm:text-lg text-base text-wrap text-center">
                    Number of deployments per day
                    </h3>
                    <h3 className="text-black font-bold sm:text-lg text-base text-wrap text-center">
                    {formik.values["execution-count-each-month"]}
                    </h3>
                </div> */}

                {/* <div className="flex flex-col rounded-[25px] h-[120px] bg-[#F0F0F0] my-10">
                    <h3 className="text-subtitle font-bold sm:text-lg text-base text-wrap text-center flex-grow grid place-content-center">
                        Total
                    </h3>
                    <div className="h-14 rounded-[25px] bg-[#CCCCCC] grid place-content-center">
                        <h3 className="text-subtitle font-bold sm:text-lg text-base text-wrap text-center">
                            {Math.round(calculateTotalCost())}
                        </h3>
                    </div>
                </div> */}
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                {/* <br></br>    */}
                <div className="w-full bg-primary-grey rounded-[1.5rem] h-[100px] flex flex-col justify-center gap-1 my-10 mb-4">
                    <h3 className="text-white font-medium sm:text-lg text-base text-wrap text-center">
                        Total Monthly Cost
                    </h3>
                    <h3 className="text-white font-bold sm:text-4xl text-3xl text-wrap text-center">
                        $ {Math.round(calculateTotalCost())}
                    </h3>
                </div>

                {/* <div className="w-full bg-primary-grey rounded-[1.5rem] h-[100px] flex flex-col justify-center gap-1 my-5 mb-4">
                    <h3 className="text-white font-medium sm:text-lg text-base text-wrap text-center">
                        Total Cost to reach 80% test coverage
                    </h3>
                    <h3 className="text-white font-bold sm:text-4xl text-3xl text-wrap text-center">

                    </h3>
                </div> */}

                {/* <h3 className="text-subtitle font-medium sm:text-lg text-base text-wrap text-center">
                    Total Cost to reach 80% test coverage
                    <br />
                    $ {Math.round(calculateTotalCost()) * 8}
                </h3> */}

            </div>
            <div className="rounded-[1.5rem] bg-white border border-black shadow-md flex flex-col px-10 py-8 xl:w-1/3 w-full flex-shrink-0 min-w-[300px] items-center">
                
                <img src={Logo} alt="logo" className="h-[100px] w-[100px] my-8" />
                <img src={SoftwareTester} alt="software_tester" className="h-max my-10" />
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                {/* <br></br> */}
                <div className="w-full bg-primary rounded-[1.5rem] h-[100px] flex flex-col justify-center gap-1 my-5 mb-4">
                    <h3 className="text-white font-medium sm:text-lg text-base text-wrap text-center">
                        Total Monthly Cost
                    </h3>
                    <h3 className="text-white font-bold sm:text-4xl text-3xl text-wrap text-center">
                        {/* ${calculateMonthlyCost()} */}
                        $ {Math.round(calculateMonthlyCost())}
                    </h3>
                </div>

                {/* <h3 className="text-subtitle font-medium sm:text-lg text-base text-wrap text-center">
                    Total Cost to reach 80%
                    <br />
                    test coverage - 15000
                </h3> */}
                {/* <div className="w-full bg-primary rounded-[1.5rem] h-[100px] flex flex-col justify-center gap-1 my-5 mb-4">
                    <h3 className="text-white font-medium sm:text-lg text-base text-wrap text-center">
                        Total Cost to reach 80% test coverage
                    </h3>
                    <h3 className="text-white font-bold sm:text-4xl text-3xl text-wrap text-center">         
                    </h3>
                </div> */}
                
            </div>
        </div>
    </div>;
}