import React from 'react';

export default function PrivacyPolicy() {
    return (
        <div className="flex flex-col items-center">
            <h1 className="text-black font-bold md:text-5xl text-4xl my-16 text-center">
                <span className="text-primary bg-white rounded-2xl md:mt-0 mt-3">QAbolts:</span>
                &nbsp;Privacy Policy
            </h1>
            <h2 className="text-black font-bold md:text-xl text-xl text-center">
                <span className="text-primary bg-white rounded-xl md:mt-0 mt-3">Last Updated:</span>
                &nbsp;10 July 2024
            </h2>

            <div className="rounded-[1.5rem] bg-white shadow-md flex flex-col md:p-8 p-4">
                {/* 1. Introduction */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">1. Introduction</li>
                    <ul className="ml-6">
                        <li className="sm:text-base text-sm text-subtitle">
                            QAbolts (“Company”, “we”, “us”, or “our”) respects your privacy and is committed to protecting it through our compliance with this policy. This Privacy Policy describes how we collect, use, and share information when you visit our website, interact with our services, or otherwise communicate with us.
                        </li>
                        <br></br>
                        <li className="sm:text-base text-sm text-subtitle">
                            By accessing or using our website or services, you agree to this Privacy Policy. If you do not agree with the practices described in this policy, please do not use our services.
                        </li>
                    </ul>
                </ul>

                {/* 2. Information We Collect */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">2. Information We Collect</li>
                    <ul className="ml-10 list-disc">
                        <li>
                            <b>2.1 Personal Information:</b> We collect personal data that can identify you directly or indirectly, such as your name, email address, phone number, billing details, and company name.
                        </li>
                        <li>
                            <b>2.2 Automatically Collected Information:</b> When you visit our website, we automatically collect data like your IP address, browser type, and pages visited using cookies and similar technologies.
                        </li>
                        <li>
                            <b>2.3 Information Provided by Clients:</b> We may collect additional information, such as login credentials and test environment access, when delivering our services.
                        </li>
                    </ul>
                </ul>

                {/* 3. How We Use Your Information */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">3. How We Use Your Information</li>
                    <ul className="ml-6">
                        <li><b>3.1 Service Provision:</b> To deliver and improve our services, communicate with you, and provide support.</li>
                        <li><b>3.2 Billing and Payments:</b> To process transactions and manage billing.</li>
                        <li><b>3.3 Marketing and Communication:</b> To send newsletters, respond to inquiries, and deliver marketing material with your consent.</li>
                        <li><b>3.4 Compliance:</b> To comply with legal obligations and protect against fraudulent activities.</li>
                    </ul>
                </ul>

                {/* 4. Legal Basis for Processing Personal Data */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">4. Legal Basis for Processing Personal Data</li>
                    <ul className="ml-6">
                        <li><b>Consent:</b> Where you have provided consent for processing.</li>
                        <li><b>Contract:</b> To fulfill our contractual obligations to you.</li>
                        <li><b>Legal Obligation:</b> To comply with legal obligations (e.g., tax reporting).</li>
                        <li><b>Legitimate Interest:</b> To pursue our legitimate interests, provided that these interests are not overridden by your privacy rights.</li>
                    </ul>
                </ul>

                {/* 5. How We Share Your Information */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">5. How We Share Your Information</li>
                    <ul className="ml-6">
                        <li><b>5.1 With Service Providers:</b> We share information with trusted third-party vendors for processing transactions, hosting, and marketing.</li>
                        <li><b>5.2 For Legal Reasons:</b> To comply with legal obligations or protect our rights, safety, and property.</li>
                        <li><b>5.3 Business Transfers:</b> In the event of a merger, acquisition, or asset sale, your data may be transferred.</li>
                    </ul>
                </ul>

                {/* 6. International Data Transfers */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">6. International Data Transfers</li>
                    <ul className="ml-6">
                        <li>Your data may be transferred and processed in countries outside of your own. We ensure appropriate safeguards are in place to protect your privacy.</li>
                    </ul>
                </ul>

                {/* 7. Your Rights */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">7. Your Rights</li>
                    <ul className="ml-6 list-disc">
                        <li><b>Access and Portability:</b> Request access to your personal data and receive it in a structured format.</li>
                        <li><b>Rectification:</b> Request correction of any inaccurate or incomplete data.</li>
                        <li><b>Erasure:</b> Request deletion of your data under certain conditions.</li>
                        <li><b>Restriction:</b> Request limited processing of your data in certain circumstances.</li>
                        <li><b>Objection:</b> Object to the processing of your data based on legitimate interests or direct marketing.</li>
                        <li><b>Withdrawal of Consent:</b> Withdraw your consent for data processing at any time.</li>
                        <li><b>Complaint:</b> File a complaint with your local data protection authority if you believe your privacy rights have been violated.</li>
                    </ul>
                </ul>

                {/* 8. Data Retention */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">8. Data Retention</li>
                    <ul className="ml-6">
                        <li>We retain your data only as long as necessary for the purposes for which it was collected, including legal and regulatory compliance.</li>
                    </ul>
                </ul>

                {/* 9. Security */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">9. Security</li>
                    <ul className="ml-6">
                        <li>We take reasonable measures to protect your data, but no system is completely secure. We cannot guarantee the absolute security of your information.</li>
                    </ul>
                </ul>

                {/* 10. Cookies and Tracking Technologies */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">10. Cookies and Tracking Technologies</li>
                    <ul className="ml-6">
                        <li>We use cookies and similar technologies to enhance your experience, analyze usage, and deliver targeted advertisements. You can manage your preferences through your browser settings.</li>
                    </ul>
                </ul>

                {/* 11. Third-Party Links */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">11. Third-Party Links</li>
                    <ul className="ml-6">
                        <li>Our website may contain links to third-party websites or services. We are not responsible for their privacy practices, and we encourage you to review their privacy policies.</li>
                    </ul>
                </ul>

                {/* 12. Changes to This Privacy Policy */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">12. Changes to This Privacy Policy</li>
                    <ul className="ml-6">
                        <li>We may update this Privacy Policy from time to time. Significant changes will be notified via email or posted on our website. Please review this policy periodically for updates.</li>
                    </ul>
                </ul>

                {/* 13. Contact Us */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">13. Contact Us</li>
                    <ul className="ml-6">
                        <li>If you have any questions about this Privacy Policy or how we handle your data, please contact us at:</li>
                        <li><b>QAbolts</b></li>
                        <li>Email: privacy@qabolts.com</li>
                        <li>Website: <a class="text-blue-600" href="http://www.qabolts.com">http://www.qabolts.com</a></li>
                    </ul>
                </ul>
            </div>
        </div>
    );
}
