import React from "react";
import { useNavigate } from "react-router-dom";
import ThankyouIcon from "../assets/PNGs/thankyou.png";
import Button from "../components/global/button";

const ThankYouPage = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/");
  };

  return (
    <div className="py-5 min-h-screen flex flex-col items-center justify-center bg-[linear-gradient(135deg,_rgba(85,_190,_245,_1)_0%,_rgba(40,_167,_255,_1)_50%,_rgba(67,_161,_246,_1)_100%)]">
      <div className="text-center">
        <img
          src={ThankyouIcon}
          alt="Thank You"
          className="w-1/2 max-w-[40%] mx-auto mb-6"
        />
        <h1 className="mt-4 mb-3 text-white text-4xl font-extrabold">
          Thank You for Contacting!
        </h1>
      </div>

      <div className="text-center mt-4">
        <Button
          type="submit"
          onClick={handleBack}
          className={`w-[200px] shadow-primary text-white shadow-md lg:self-start self-center bg-[linear-gradient(135deg,_rgba(0,_123,_255,_1)_0%,_rgba(0,_92,_185,_1)_100%)]`}
          label="Back"
        />
      </div>
    </div>
  );
};

export default ThankYouPage;
