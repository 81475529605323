import React from 'react';

export default function TermsAndConditions() {
    return (
        <div className="flex flex-col items-center">
            <h1 className="text-black font-bold md:text-5xl text-4xl my-16 text-center">
                <span className="text-primary bg-white rounded-2xl md:mt-0 mt-3">QAbolts:</span>
                &nbsp;Terms and Conditions
            </h1>
            <h2 className="text-black font-bold md:text-xl text-xl text-center">
                <span className="text-primary bg-white rounded-xl md:mt-0 mt-3">Last Updated:</span>
                &nbsp;10 July 2024
            </h2>

            <div className="rounded-[1.5rem] bg-white shadow-md flex flex-col md:p-8 p-4">
                {/* 1. Introduction */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">1. Introduction</li>
                    <ul className="ml-6">
                        <li className="sm:text-base text-sm text-subtitle">
                            Welcome to QAbolts. These Terms and Conditions ("Terms") govern your use of our website, platform, and services ("Services") provided by QAbolts ("Company", "we", "us", or "our"). By accessing or using our Services, you agree to comply with and be bound by these Terms. Please read them carefully.
                        </li>
                        <br></br>
                        <li className="sm:text-base text-sm text-subtitle">
                            If you do not agree to these Terms, you should not use the Services.
                        </li>
                    </ul>
                </ul>

                {/* 2. Definitions */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">2. Definitions</li>
                    <ul className="ml-10 list-disc">
                        <li><b>“Client”</b> refers to any individual or entity that engages QAbolts for services.</li>
                        <li><b>“Services”</b> include any and all QA automation services provided by QAbolts, including test automation, infrastructure management, consulting, and any related products.</li>
                        <li><b>“Website”</b> refers to QAbolts' official website, located at qabolts.com.</li>
                    </ul>
                </ul>

                {/* 3. Eligibility */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">3. Eligibility</li>
                    <ul className="ml-6">
                        <li>You must be at least 18 years old to access and use our Services. By using our Services, you represent that you are legally capable of entering into binding contracts.</li>
                    </ul>
                </ul>

                {/* 4. Services Provided */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">4. Services Provided</li>
                    <ul className="ml-6">
                        <li>QAbolts offers the following services:</li>
                    </ul>
                    <ul className="ml-10 list-disc">
                        <li><b>End-to-End QA Automation:</b> Comprehensive automation testing solutions tailored to client needs.</li>
                        <li><b>Infrastructure Management:</b> Ensuring scalable and robust test environments.</li>
                        <li><b>Consultation:</b> Strategic guidance on quality assurance and automation strategies.</li>
                        <li><b>Support:</b> Ongoing assistance for implemented test frameworks and solutions.</li>
                    </ul>
                    <ul className="ml-6">
                        <li>These services are customized based on individual contracts and agreements.</li>
                    </ul>
                </ul>

                {/* 5. Fees and Payments */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">5. Fees and Payments</li>
                    <ul className="ml-6">
                        <li><b>Service Fees:</b> Service fees are determined based on individual contracts with clients. All fees are specified in the contract or invoice.</li>
                        <li><b>Payment Terms:</b> Payments are due within 30 days of the invoice date unless otherwise stated. Delayed payments may incur late fees.</li>
                        <li><b>Refunds:</b> QAbolts does not provide refunds for Services rendered unless explicitly agreed upon.</li>
                    </ul>
                </ul>

                {/* 6. Client Responsibilities */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">6. Client Responsibilities</li>
                    <ul className="ml-6">
                        <li><b>Accuracy of Information:</b> Clients must provide accurate and complete information necessary for QAbolts to deliver Services.</li>
                        <li><b>Access:</b> Clients must provide QAbolts with access to necessary platforms, tools, and environments to perform Services.</li>
                        <li><b>Cooperation:</b> Timely cooperation from the Client’s team is essential for QAbolts to meet agreed-upon deadlines.</li>
                    </ul>
                </ul>

                {/* 7. Intellectual Property */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">7. Intellectual Property</li>
                    <ul className="ml-6">
                        <li><b>Ownership:</b> All intellectual property related to tools, scripts, test cases, and any other work product generated by QAbolts during the Services is owned by QAbolts unless otherwise agreed.</li>
                        <li><b>License:</b> Upon full payment, QAbolts grants the Client a non-exclusive, non-transferable license to use the deliverables provided as part of the Services.</li>
                        <li><b>Client Content:</b> Clients retain ownership of any content or data provided to QAbolts for the purposes of delivering the Services.</li>
                    </ul>
                </ul>

                {/* 8. Confidentiality */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">8. Confidentiality</li>
                    <ul className="ml-6">
                        <li>Both parties agree to keep confidential any non-public information that is disclosed during the course of the Services. This includes, but is not limited to, client business processes, data, and any other proprietary information. Confidentiality obligations shall survive the termination of these Terms.</li>
                    </ul>
                </ul>

                {/* 9. Limitation of Liability */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">9. Limitation of Liability</li>
                    <ul className="ml-6">
                        <li><b>No Warranty:</b> QAbolts provides Services "as is" without any warranty, express or implied. We do not guarantee error-free or uninterrupted operation of our Services.</li>
                        <li><b>Limitation of Liability:</b> To the maximum extent permitted by law, QAbolts' total liability to the Client shall not exceed the total amount paid by the Client for Services during the last six months preceding the claim. QAbolts is not liable for any indirect, incidental, or consequential damages.</li>
                    </ul>
                </ul>

                {/* 10. Termination */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">10. Termination</li>
                    <ul className="ml-6">
                        <li><b>Termination by Client:</b> The Client may terminate the Services at any time with 30 days' written notice.</li>
                        <li><b>Termination by QAbolts:</b> QAbolts reserves the right to terminate Services if the Client fails to meet payment obligations, violates any terms of this agreement, or engages in activities that could harm QAbolts’ reputation.</li>
                        <li><b>Effect of Termination:</b> Upon termination, the Client must pay for all Services rendered up to the termination date. Both parties must return or destroy any confidential information in their possession.</li>
                    </ul>
                </ul>

                {/* 11. Indemnification */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">11. Indemnification</li>
                    <ul className="ml-6">
                        <li>The Client agrees to indemnify, defend, and hold harmless QAbolts, its affiliates, and employees from any claims, damages, or expenses arising from the Client’s breach of these Terms or from the Client’s use of the Services.</li>
                    </ul>
                </ul>

                {/* 12. Force Majeure */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">12. Force Majeure</li>
                    <ul className="ml-6">
                        <li>QAbolts is not liable for any failure to perform due to circumstances beyond our reasonable control, including but not limited to natural disasters, strikes, acts of war, or government actions.</li>
                    </ul>
                </ul>

                {/* 13. Amendments */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">13. Amendments</li>
                    <ul className="ml-6">
                        <li>QAbolts reserves the right to modify these Terms at any time. Clients will be notified of significant changes via email or a notice on our website. Continued use of our Services following such changes constitutes acceptance of the revised Terms.</li>
                    </ul>
                </ul>

                {/* 14. Governing Law */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">14. Governing Law</li>
                    <ul className="ml-6">
                        <li>These Terms shall be governed by and construed in accordance with the laws of Thailand/Bangkok, without regard to its conflict of law principles.</li>
                    </ul>
                </ul>

                {/* 15. Dispute Resolution */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">15. Dispute Resolution</li>
                    <ul className="ml-6">
                        <li>Any disputes arising out of or related to these Terms or our Services shall be resolved through good faith negotiations. If the dispute cannot be resolved amicably, it shall be submitted to binding arbitration in accordance with the rules of the Thailand Arbitration Center.</li>
                    </ul>
                </ul>

                {/* 16. Entire Agreement */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">16. Entire Agreement</li>
                    <ul className="ml-6">
                        <li>These Terms, along with any additional agreements or contracts signed between the Client and QAbolts, represent the entire agreement between the parties and supersede any prior agreements or understandings.</li>
                    </ul>
                </ul>

                {/* 17. Contact Information */}
                <ul className="flex flex-col mb-10">
                    <li className="text-black font-bold sm:text-2xl text-xl">17. Contact Information</li>
                    <ul className="ml-6">
                        <li>For any questions or concerns regarding these Terms or the Services, please contact us at:</li>
                        <li><b>QAbolts</b></li>
                        <li>Email: support@qabolts.com</li>
                        <li>Website: <a class="text-blue-600" href="http://www.qabolts.com">http://www.qabolts.com</a></li>
                    </ul>
                </ul>
            </div>
        </div>
    );
}
